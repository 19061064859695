import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, LinkBox, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Radiant Auto Spa
			</title>
			<meta name={"description"} content={"Бездоганна чистота, бездоганний догляд - Radiant Auto Spa!"} />
			<meta property={"og:title"} content={"Radiant Auto Spa"} />
			<meta property={"og:description"} content={"Бездоганна чистота, бездоганний догляд - Radiant Auto Spa!"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6698e1de2fc7070018b9f8e0/images/2-3.jpg?v=2024-07-18T09:42:36.086Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6698e1de2fc7070018b9f8e0/images/2-3.jpg?v=2024-07-18T09:42:36.086Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6698e1de2fc7070018b9f8e0/images/2-3.jpg?v=2024-07-18T09:42:36.086Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6698e1de2fc7070018b9f8e0/images/2-3.jpg?v=2024-07-18T09:42:36.086Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6698e1de2fc7070018b9f8e0/images/2-3.jpg?v=2024-07-18T09:42:36.086Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6698e1de2fc7070018b9f8e0/images/2-3.jpg?v=2024-07-18T09:42:36.086Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6698e1de2fc7070018b9f8e0/images/2-3.jpg?v=2024-07-18T09:42:36.086Z"} />
		</Helmet>
		<Components.Header2>
			<Override slot="link4" />
			<Override slot="link3" />
			<Override slot="text" />
			<Override slot="text1" />
			<Override slot="link5" />
			<Override slot="text3" />
			<Override slot="text2" />
			<Override slot="link" />
			<Override slot="link2" />
			<Override slot="link1" />
		</Components.Header2>
		<Components.Hero>
			<Override slot="text" />
			<Override slot="text1" />
			<Override slot="box3" />
			<Override slot="box1" />
			<Override slot="box" />
			<Override slot="icon" />
			<Override slot="icon1" />
			<Override slot="text3" />
			<Override slot="text2" />
		</Components.Hero>
		<Section
			padding="80px 0 90px 0"
			quarkly-title="Headline-4"
			border-width="0 0 .1px 0"
			border-style="solid"
			border-color="--color-darkL2"
		>
			<Override slot="SectionContent" sm-min-width="280px" />
			<Box min-width="100px" min-height="100px" display="flex" lg-flex-direction="column">
				<Text
					margin="0px 0px 0px 0px"
					width="50%"
					font="--headline2"
					padding="0px 50px 0px 0px"
					lg-width="100%"
					lg-margin="0px 0px 50px 0px"
					md-padding="0px 0 0px 0px"
					md-font="normal 500 42px/1.2 --fontFamily-serifTimes"
					sm-margin="0px 0px 35px 0px"
				>
					Про нас
				</Text>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					display="flex"
					flex-direction="column"
					justify-content="center"
					lg-width="100%"
				>
					<Text margin="0px 0px 20px 0px" font="--lead" color="--dark" sm-margin="0px 0px 25px 0px">
						Radiant Auto Spa поєднує в собі передові технології та пристрасть до автомобілів. Наша екологічно чиста автомийка використовує найсучасніше обладнання, щоб забезпечити глибоке, безпечне очищення, зберігаючи як ваш автомобіль, так і навколишнє середовище. Наша команда, що має багаторічний досвід у сфері автосервісу, зосереджується на досягненні досконалості та задоволенні потреб клієнтів у кожній послузі.

Translated with DeepL.com (free version)
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="News/Streams/Blog-9">
			<Override slot="SectionContent" md-margin="0px 35px 0px 35px" sm-margin="0px 15px 0px 15px" />
			<Box
				min-width="10px"
				min-height="10px"
				display="flex"
				justify-content="space-between"
				align-items="center"
				margin="0px 0px 70px 0px"
				lg-flex-direction="column"
				text-align="center"
				flex-direction="column"
				max-width="100%"
			>
				<Text
					margin="0px 0px 25px 0px"
					font="--headline2"
					color="--darkL1"
					lg-margin="0px 0px 30px 0px"
					lg-text-align="center"
				>
					Чому Radiant Auto Spa?
				</Text>
				<Text margin="0px 0px 20px 0px" color="--darkL1" font="--base" width="70%">
					Ihre Privatsphäre und Ihr Vertrauen haben oberste Priorität. Wir halten die strengsten Standards der Vertraulichkeit und ethischen Praxis ein.
				</Text>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				md-grid-template-columns="1fr"
				lg-grid-template-columns="repeat(2, 1fr)"
				lg-grid-template-rows="auto"
				grid-gap="0 35px"
				lg-grid-gap="40px 35px"
				md-grid-gap="35px 0"
			>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="flex-end"
					padding="30px 30px 30px 30px"
					background="linear-gradient(180deg,rgba(7, 11, 57, 0.15) 0%,rgba(5, 4, 49, 0.7) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/6698e1de2fc7070018b9f8e0/images/2-2.jpg?v=2024-07-18T09:42:36.085Z) center/cover repeat scroll padding-box"
					height="480px"
				>
					<LinkBox align-items="center" margin="0px 0px 0 0px" href="/">
						<Text
							margin="0px 0px 2rem 0px"
							font="--headline3"
							color="--light"
							text-align="center"
							height="auto"
						>
							Ефективність
						</Text>
						<Text
							margin="0px 0px 0 0px"
							font="--base"
							color="--light"
							text-align="center"
							height="auto"
						>
							Мінімальний час очікування з максимальним результатом.
						</Text>
					</LinkBox>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="flex-end"
					padding="30px 30px 30px 30px"
					background="linear-gradient(180deg,rgba(7, 11, 57, 0.15) 0%,rgba(5, 4, 49, 0.7) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/6698e1de2fc7070018b9f8e0/images/2-1.jpg?v=2024-07-18T09:42:36.065Z) center/cover repeat scroll padding-box"
					height="480px"
				>
					<LinkBox align-items="center" margin="0px 0px 0 0px" href="/">
						<Text
							margin="0px 0px 2rem 0px"
							font="--headline3"
							color="--light"
							text-align="center"
							height="auto"
						>
							Ретельність
						</Text>
						<Text
							margin="0px 0px 0 0px"
							font="--base"
							color="--light"
							text-align="center"
							height="auto"
						>
							Ретельне очищення, завдяки якому ваш автомобіль буде виглядати якнайкраще.
						</Text>
					</LinkBox>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="flex-end"
					padding="30px 30px 30px 30px"
					background="linear-gradient(180deg,rgba(7, 11, 57, 0.15) 0%,rgba(5, 4, 49, 0.7) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/6698e1de2fc7070018b9f8e0/images/1-2.jpg?v=2024-07-18T09:42:36.063Z) center/cover repeat scroll padding-box"
					height="480px"
				>
					<LinkBox align-items="center" margin="0px 0px 0 0px" href="/">
						<Text
							margin="0px 0px 2rem 0px"
							font="--headline3"
							color="--light"
							text-align="center"
							height="auto"
						>
							Бюджетність
						</Text>
						<Text
							margin="0px 0px 0 0px"
							font="--base"
							color="--light"
							text-align="center"
							height="auto"
						>
							Послуги преміум-класу за конкурентними цінами.
						</Text>
					</LinkBox>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" background="--color-darkL2">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
				padding="0px 80px 0px 0px"
				lg-padding="0px 30px 0px 0px"
				md-padding="0px 0px 0px 0px"
			>
				<Text margin="0px 0px 0px 0px" color="--light" font="--headline2" md-margin="0px 0px 30px 0px">
					Огляд послуг
				</Text>
				<Button type="link" text-decoration-line="initial" text-align="center" href="/services">
					Всі послуги
				</Button>
			</Box>
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
			>
				<Text margin="0px 0px 10px 0px" color="#F7FBFF" font="--headline3">
					Зовнішня мийка
				</Text>
				<Text margin="0px 0px 10px 0px" color="#F7FBFF" font="--headline3">
					Прибирання салону
				</Text>
				<Text margin="0px 0px 10px 0px" color="#F7FBFF" font="--headline3">
					Захист лакофарбового покриття
				</Text>
				<Text margin="0px 0px 10px 0px" color="#F7FBFF" font="--headline3">
					Очищення двигуна
				</Text>
			</Box>
		</Section>
		<Components.Cta>
			<Override slot="text">
				Завітайте до нас сьогодні!
			</Override>
			<Override slot="text1">
				Подаруйте своєму автомобілю розкіш, на яку він заслуговує, в автосалоні Radiant Auto Spa. Заплануйте свій візит зараз і дізнайтеся, чому ми є найкращим вибором для догляду за автомобілем. Сяйте яскраво з кожною поїздкою!
			</Override>
		</Components.Cta>
		<Components.Header2>
			<Override slot="text" />
		</Components.Header2>
		<Components.Footer>
			<Override slot="text" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"66028e06867c86001f1d8b21"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});